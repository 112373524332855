import logo from './logo.svg';
import './App.css';
import Message from "./Message";
import React, { Component } from 'react';


function App() {
  return (
    <div className="App">
        <Message></Message>
    </div>
  );
}

export default App;
